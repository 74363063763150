import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import SEO from "../components/seopagetagcat"
import { SlugLink } from '../components/utils'
import moment from "moment";
const baseuri = "https://dmf5.xyz"
class Pages extends React.Component {
  render() {
    const page = this.props.data.privateGraphql.getpage
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    // const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    // const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={page.metatitle}
          description={page.metadescription}
          image={`${page.image}`}
          isBlogPost={true}
          datePublished={page.publishDate}
        />
        <article className="w-layout-container--narrow w-post-content" style={{maxWidth:1200}}>
          <header className="w-article-header">
            <h1 className="w-article-header__headline">{page.title}</h1>
            <div className="w-author__published">
              <time>{moment(page.publishDate).format('DD/MM/YYYY h:mm')}</time>
            </div>
          </header>
          <section dangerouslySetInnerHTML={{ __html: page.detail }} />
          <footer>
          </footer>
        </article>
      </Layout>
    )
  }
}

export default Pages

export const pageQuery = graphql`
  query ($slugtitle: String = "404", $siteId: Int = 1051) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getpage(slugtitle: $slugtitle, siteId: $siteId) {
        id
        detail
        image
        metadescription
        metatitle
        publishDate
        short
        title
        slugtitle
      }
    }
  }
`
